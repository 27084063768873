<template>
  <v-app>
    <div class="reset-password">
      <v-container>
        <v-row>
          <v-col>
            <div class="circle-logo text-center pt-12">
              <logo-img></logo-img>
            </div>
            <div class="logo-img">
              <div class="text-center">
                <logo class="logo" />
                <div class="caption primary--text">
                  Manager
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <div class="wrapper">
              <h1 class="h1 text-center">Reset Password</h1>
              <p v-if="status.email" class="text-center">
                We've sent the confirmation code to your email
                <b>{{ status.email }}</b
                >.
              </p>

              <v-form
                ref="form"
                v-model="valid"
                @submit.prevent="reset"
                lazy-validation
              >
                <v-text-field
                  type="number"
                  label="Confirmation Code"
                  v-model="code"
                  :rules="codeRules"
                  :error-messages="errors.confirmation_code"
                >
                </v-text-field>
                <v-text-field
                  type="password"
                  label="New Password"
                  v-model="password"
                  :rules="passwordRules"
                  :error-messages="errors.password"
                  :loading="loading"
                  required
                >
                </v-text-field>
                <v-text-field
                  type="password"
                  label="Confirm Password"
                  v-model="password_confirmation"
                  :error-messages="passwordConfirmationErrors"
                  :loading="loading"
                  required
                >
                </v-text-field>

                <v-btn
                  type="submit"
                  color="primary"
                  :disabled="!valid || loading || saving"
                  :loading="loading"
                  block
                >
                  Update Password
                </v-btn>

                <p class="overline mt-6" v-if="showResend">
                  Didn't receive confirmation code?
                  <router-link :to="{ name: 'RequestPasswordReset' }">
                    Resend Code
                  </router-link>
                  <br />
                  Don't have account?
                  <router-link :to="{ name: 'Register' }">Register</router-link>
                </p>
                <p class="overline mt-6 text-center" v-else>
                  Resend Code In ({{
                    status.reset_password_code_resend_seconds || 0
                  }}) secs
                  <br />
                  Dont have account?
                  <router-link :to="{ name: 'Register' }">Register</router-link>
                </p>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import Logo from "@/components/Logo";
import LogoImg from "@/components/LogoImg";

import { pendingResetPassword, resetPassword } from "@/services/http/passwords";
import { codeRules, passwordRules } from "@/services/validators";

export default {
  name: "ResetPassword",
  components: {
    Logo,
    LogoImg,
  },
  data() {
    return {
      loading: true,
      saving: false,
      valid: true,
      interval: null,
      errors: {},
      status: {},
      password: "",
      password_confirmation: "",
      code: "",

      codeRules,
      passwordRules,
    };
  },
  created() {
    this.load();
    this.startTick();
  },
  destroyed() {
    if (this.interval) clearInterval(this.interval);
  },
  methods: {
    async load() {
      try {
        this.status = await pendingResetPassword(this.$route.params.id);
      } catch (e) {
        this.$toast(e.toString());
      } finally {
        this.loading = false;
      }
    },
    async reset() {
      try {
        if (this.$refs.form.validate()) {
          this.saving = true;
          const id = this.$route.params.id;
          await resetPassword(
            id,
            this.code,
            this.password,
            this.password_confirmation
          );
          await this.$toast(
            "Your password has beed updated. Please login now."
          );
          this.$router.push({ name: "Login" });
        }
      } catch (e) {
        this.errors = e;
      } finally {
        this.saving = false;
      }
    },
    startTick() {
      this.interval = setInterval(() => {
        this.status.reset_password_code_resend_seconds =
          this.status.reset_password_code_resend_seconds || 0;
        this.status.reset_password_code_resend_seconds--;
      }, 1000);
    },
  },
  computed: {
    passwordConfirmationErrors() {
      if (this.password != this.password_confirmation)
        return "Passwords did not match";
      if (this.errors.password_confirmation)
        return this.errors.password_confirmation;
      else return null;
    },
    showResend() {
      const secs = this.status.reset_password_code_resend_seconds || 0;
      return secs < 1;
    },
  },
  watch: {
    code(v) {
      if (v) this.errors.confirmation_code = null;
    },
    password(v) {
      if (v) this.errors.password = null;
    },
    password_confirmation(v) {
      if (v) this.errors.password_confirmation = null;
    },
  },
};
</script>
